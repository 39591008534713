          <template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm2 md3 text-left pt-1>
        <span class="mainHeader3">Add Batch:</span>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm2 md2 pr-1 pt-1>
        <v-text-field
          v-model="BatchCode"
          outlined
          label="Batch Code"
          type="text"
          color="#b234a9"
          dense
          hide-details
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm2 md2 pr-1 pt-1>
        <v-autocomplete
                    :items="SupplierList"
                    v-model="supplierName"
                    placeholder="Select acidifier"
                    item-text="supplierName"
                    item-value="_id"
                    clearable
                    outlined flat class="txtfield2"
                    color="#b234a9"
                    hide-details
                    dense
                  ></v-autocomplete
                >
      </v-flex>
      <v-flex xs12 sm2 md2 pr-1 pt-1>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              hide-details=""
              color="#b234a9"
              append-icon="mdi-calendar-multiselect"
              readonly
              label="Date"
              v-model="date"
              v-bind="attrs"
              v-on="on"
              @click:clear="from = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            color="#571964"
            @change="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <!-- <v-flex xs12 sm3 md2 mt-1 mr-0 mr-lg-1 class="buttons1" pt-2 pt-sm-0 text-right>
        <v-btn
          dark block
          color="#3F053C"
          @click="$router.push('/AcidingList')"
          class="buttons1"
        >
          Aciding List
        </v-btn>
      </v-flex> -->
      <!-- <v-flex xs12 sm3 md2 mt-1  class="buttons1" pt-2 pt-sm-0 text-right>
        <v-btn
          dark block
          color="#3F053C"
          @click="$router.push('/ReturnAciding')"
          class="buttons1"
        >
          After Aciding
        </v-btn>
      </v-flex> -->
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 py-4>
        <v-card
          tile
          elevation="0"
          class="rounded-lg pb-2"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout px-4 py-4 wrap v-if="List">
            <v-flex xs12 v-if="List.length > 0">
              <v-card class="pb-2" elevation="0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead style="text-transform: uppercase">
                          <tr>
                            <th class="text-left subhed"><b>Action</b></th>
                            <!-- <th class="text-left subhed"><b>Sno.</b></th> -->
                            <th class="text-left subhed">
                              <b>Bill No.</b>
                            </th>
                            <th class="text-left subhed"><b>Date</b></th>
                            <th
                              class="text-left subhed"
                              style="min-width: 20px"
                            >
                              <b>Gold Rate</b>
                            </th>
                            <th class="text-left subhed"><b>Gr.wt(gm)</b></th>
                            <th class="text-left subhed"><b>Net.wt(gm)</b></th>
                            <th class="text-left subhed"><b>Melt(%)</b></th>
                            <th class="text-left subhed">
                              <b>Total Amt.(₹)</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in List"
                            :key="i"
                            class="table text-left"
                          >
                            <td>
                              <v-checkbox 
                                      color="#3F053C"
                                @click="
                                  selectThis(value.checkbox, value._id, value)
                                "
                                v-model="value.checkbox"
                                hide-details
                              ></v-checkbox>
                            </td>
                            <td>
                              <span v-if="value.billNo">{{
                                value.billNo
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.date">
                                {{ formatDate(value.date) }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.goldRate">
                                {{ value.goldRate }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.totalGrossWeight">{{
                                value.totalGrossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.totalNetWeight">{{
                                value.totalNetWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.totalMelt">{{
                                value.totalMelt
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.totalAmount">{{
                                value.totalAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1">
              No data found
            </v-flex>
          </v-layout>

          <v-layout class="subhed2" justify-end v-if="check2 == 'true'">
            <v-flex xs12 sm4 px-1>
              <v-layout wrap py-2>
                <v-flex
                  xs12
                  sm7
                  text-left
                  align-self-center
                  v-if="totalnetweight"
                >
                  Total Net Weight(gm) :
                </v-flex>
                <v-flex xs12 sm5 pr-5 v-if="totalnetweight">
                  <v-card outlined class="pa-2">
                    {{ totalnetweight.toFixed(2) }}</v-card
                  ></v-flex
                >
              </v-layout>
              <v-layout justify-end v-if="check2 == 'true'">
                <v-flex xs3 py-2 px-4 class="buttons1">
                  <v-btn color="#3F053C" dark @click="valid()" block>
                    Save
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
          <script>
import axios from "axios";
export default {
  props: ["title2"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      juwellaryId: localStorage.getItem("juwellaryId"),
      taxType: localStorage.getItem("GSTstatus"),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      List: [],
      selected: [],
      selectedItems: [],
      check2: false,
      menu2: false,
      flag1: false,
      sflag: true,
      BatchCode: null,
      goldRate: "",
      totalnetweight: "",
      supplierName:"",
    };
  },
  mounted() {
    this.getBatchCode();
    this.getBill();
    this.getSupplier();
    // this.getBatch();
  },
  methods: {
    markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = true;
          this.selected.push(this.savedList[i]._id);
        }
        console.log("markeD", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = false;
          this.selected = [];
        }
        console.log("Rem", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id, items1) {
      this.isSelectAll = false;
      // console.log("items1=", items1);
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
            this.selectedItems.push(items1);
            console.log("ll=");
          }
        } else {
          this.selected.push(id);
          this.selectedItems.push(items1);
        }
      } else {
        var ar = this.selected;
        var aritem = this.selectedItems;
        this.selected = ar.filter((x) => x != id);
        this.selectedItems = aritem.filter(
          (x) => x._id.toString() != id.toString()
        );
      }

      console.log("selected id==", this.selected);
      console.log("selectedItems==", this.selectedItems);
      console.log("savedListCopy==", this.savedListCopy);
      this.calctotal();
    },
    calctotal() {
      axios({
        method: "Post",
        url: "/oldgoldbill/calculation",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          OldGoldBills: this.selectedItems,
          // salesId: this.salesId,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.totalnetweight = response.data.totalnetweight;
            if (this.totalnetweight) {
              this.check2 = "true";
              console.log("this.totalnetweight==", this.totalnetweight);
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getBatchCode() {
      axios({
        url: "/batchcode/generate",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.BatchCode = response.data.batchno;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.getBill();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    valid() {
      if (!this.BatchCode) {
        this.msg = "Please provide batch code";
        this.showsnackbar = true;
        return;
      } else if (!this.date) {
        this.msg = "Please choose date";
        this.showsnackbar = true;
        return;
      } else if (!this.selectedItems.length > 0) {
        this.msg = "Please select bills for aciding";
        this.showsnackbar = true;
        return;
      } else {
        this.AddToBatch();
      }
    },
    AddToBatch() {
      axios({
        url: "/OldGoldBatch/add",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          oldGoldBills: this.selectedItems,
          batchNumber: this.BatchCode,
          batchDate: this.date,
          acidifier:this.supplierName,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            console.log("1")
            this.selectedItems = [];
            (this.BatchCode = ""),
              (this.date = ""),
              // this.getBill();
              this.$router.push("/AcidingList");
          } 
           else if (response.data.status == false && response.data.isExist == true) {
            console.log("2")
            this.msg = "Sorry this batch code was already used";
            this.showsnackbar = true;
            this.selectedItems = [];
           this.getBatchCode();
          //  this.getBill();
          } else {
            console.log("3")
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getBill() {
      axios({
        url: "/oldgold/bill/searchlist",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List = response.data.oldgoldBill;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSupplier() {
      axios({
        method: "get",
        url: "/supplier/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.SupplierList = response.data.data;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // getBatch() {
    //   axios({
    //     url: "/oldgoldbatch/list",
    //     method: "get",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.List = response.data.oldgoldBill;
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
          